import React from 'react';
import './App.css';
import HomeView from './views/Home.js'

function App() {
  return (
    <div className="App">
      <header className="App-above-header">
        <br/>&nbsp;
      </header>

      <header className="App-header">
          <table className="App-table-header">
              <tr>
                  <td>
                      <div className="header-contact">
                          <table>
                              <tr>
                                  <td>
                                      <header className="title-header"><a href="https://www.linkedin.com/in/chris-newman-512a38/" class="linkout">Chris Newman</a>
                                          &nbsp; <a class="linkout" href="web@floor51.com">web@mode51.com</a>
                                      </header>
                                  </td>
                                  <td width={20}></td>
                                  <td>
                                      <header className="subtitle-header">Java - C - Linux - MySQL - Android - Spring</header>
                                  </td>
                              </tr>
                          </table>
                      </div>
                  </td>
                  <td>
                      <div className="App-logo"><img src="/images/mode51.png" width="125" className="App-logo" alt="logo"/></div>
                  </td>
              </tr>
          </table>
      </header>
      <header className="App-body">
        <br/>
        <HomeView/>
      </header>
    </div>
  );
}

export default App;