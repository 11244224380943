import React from 'react';
import GridLayout from 'react-grid-layout';
import '../App.css';

export default class HomeView extends React.Component {
    render() {
        // layout is an array of objects, see the demo for more complete usage
        var layout = [
            {i: 'rx51netpanel', x: 0, y: 0, w: 4, h: 2, static: true},
            {i: 'hsmpanel', x: 0, y: 4, w: 5, h: 2, static: true},
            {i: 'simpanel', x: 5, y: 4, w: 4, h: 2, static: true},
            {i: 'rx51indoorplus', x: 4, y: 0, w: 5, h: 2, static: true},
            {i: 'sdr', x: 0, y: 8, w: 4, h: 2, static: true},
            {i: 'cozapp', x: 0, y: 12, w: 3, h: 2, static: true},
            {i: 'eewidgets', x: 3, y: 12, w: 3, h: 2, static: true},
            {i: 'pulsepaper', x: 6, y: 12, w: 3, h: 2, static: true},
            {i: 'touchticker', x: 9, y: 12, w: 3, h: 2, static: true},
            {i: 'skills', x: 9, y: 0, w: 2, h: 2, static: true}
        ];
        return (
            <GridLayout className="grid-layout" layout={layout} cols={12} rowHeight={30} width={1400}>
                <div key="hsmpanel">
                    <table width="100%" className="panel-table">
                        <tr>
                            <td>
                                <header className="panel-header">SafeNet HSM</header>
                                <p className="panel-body">
                                    Custom firmware for ProtectServer and Luna HSMs providing
                                    <br/>secured business logic
                                    for SIM provisioning and authentication
                                </p>
                            </td>
                            <td>
                                <a href="/files/HSiM51-Flyer-AW.pdf">
                                    <img src="/images/hsim51-flyer-thumb-20180105.jpg" className="panel-thumb" width={65}/>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="simpanel">
                    <table width="100%" className="panel-table">
                        <tr>
                            <td>
                                <header className="panel-header">SIM Provisioning</header>
                                <p className="panel-body">
                                    <br/>Pre-RSP SIM Provisioning
                                    <br/>Secure Credentials Generation
                                </p>
                            </td>
                            <td>
                                <a href="/files/SECURING_SIM_CREDENTIALS_GENERATION-20200316.pdf">
                                    <img src="/images/cryptoserver.png" className="panel-thumb" width={80}/>
                                </a>
                                <a href="/files/HENRY_SIM_MANAGEMENT-20200315.pdf">
                                    <img src="/images/henry.png" className="panel-thumb" width={60}/>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="rx51netpanel">
                    <table width="100%" className="panel-table">
                        <tr>
                            <td>
                               <header className="panel-header">RX-51 Net</header>
                                <p className="panel-body">
                                    Autonomous Android probes
                                    <br/>for mobile coverage analysis
                                </p>
                            </td>
                            <td>
                                <a href="/images/rx51-home.png">
                                    <img src="/images/rx51-home.png" className="panel-thumb" width={50}/>
                                </a>
                                <a href="/files/RX51Net-Flyer-AW.pdf">
                                    <img src="/images/rx51-net-flyer-thumb-20180104.jpg" className="panel-thumb" width={65}/>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="rx51indoorplus">
                    <table width="100%" className="panel-table">
                        <tr>
                            <td>
                                <header className="panel-header">SigSite AKA Indoor Plus v2</header>
                                <p className="panel-body">
                                    Automatic floorplan generation
                                    <br/>with coverage collection
                                </p>
                            </td>
                            <td>
                                <a href="https://www.youtube.com/watch?v=4Oo66-QQvv4">
                                    <img src="/images/sigsite-bradfield.png" className="panel-thumb" width={65}/>
                                </a>
                                <a href="https://www.youtube.com/watch?v=LCINuBhq7j0">
                                    <img src="/images/rx51idp-inuse.png" className="panel-thumb" width={65}/>
                                </a>
                                <a href="/files/RX51-IndoorPlus-Flyer-v1.pdf">
                                    <img src="/images/rx51-indplus-thumb.jpg" className="panel-thumb" width={65}/>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="sdr">
                    <table width="100%" className="panel-table">
                        <tr>
                            <td>
                                <header className="panel-header">SDR R&D</header>
                                <p className="panel-body">
                                    Software Defined Radio based
                                    <br/>anomaly detection and signal analysis
                                </p>
                            </td>
                            <td>
                                <a href="/files/RX51-SDR-Flyer-AW-10052018.pdf">
                                    <img src="/images/rx51-sdrscan-flyer-thumb-20180105.jpg" className="panel-thumb" width={65}/>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="cozapp">
                    <table width="100%" className="panel-table">
                        <tr>
                            <td>
                                <header className="panel-header">Cozapp</header>
                                <p className="panel-body">Android Messaging</p>

                            </td>
                            <td>
                                <a href="https://www.cozapp.co.uk/"><img src="/images/cozapp-chatland.png" className="panel-thumb" width={60}/></a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="eewidgets">
                    <table width="100%" className="panel-table" height={117}>
                        <tr>
                            <td>
                                <header className="panel-header">EE Widgets</header>
                                <p className="panel-body">For Account Balances</p>
                            </td>
                            <td>
                                <a href="http://www.eewidgets.co.uk/apps/android/eewidget/screens.html"><img src="/images/eewidgets.png" className="panel-thumb" width={45}/></a>
                                <a href="https://www.youtube.com/watch?v=LCINuBhq7j0"><img src="/images/eewidgets-standard-20130306.png" className="panel-thumb" width={45}/></a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="pulsepaper">
                    <table width="100%" className="panel-table">
                        <tr>
                            <td>
                                <header className="panel-header">pulsepaper</header>
                                <p className="panel-body">Android Wallpaper</p>
                            </td>
                            <td>
                                <a href="https://www.youtube.com/watch?v=rzRubiWsWtI"><img src="/images/pulsepaper.png" className="panel-thumb" width={55}/></a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="touchticker">
                    <table width="100%" className="panel-table">
                        <tr>
                            <td>
                                <header className="panel-header">touchticker</header>
                                <p className="panel-body">Symbian Ticker</p>
                            </td>
                            <td>
                                <a href="https://www.youtube.com/watch?v=JwK1MM6oxj8"><img src="/images/touchticker.png" className="panel-thumb" width={65}/></a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div key="skills">
                    <img src="/images/skills.png" width={250}/>
                </div>
            </GridLayout>
        )
    }
}

